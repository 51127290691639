import Menu from './components/Header';
import Footer from './components/Footer'
import HeroSection from './components/Hero'
import Cards from './components/Cards'

function App() {
  return (
    <>
      <Menu />
      <HeroSection />
      <Cards />
      <Footer/>
    </>
  )
}

export default App;
